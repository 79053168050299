export const FETCH_REQUEST_REQUEST = "FETCH_REQUEST_REQUEST";
export const FETCH_REQUEST_SUCCESS = "FETCH_REQUEST_SUCCESS";
export const FETCH_REQUEST_FAIL = "FETCH_REQUEST_FAIL";

export const ADD_REQUEST_REQUEST = "ADD_REQUEST_REQUEST";
export const ADD_REQUEST_SUCCESS = "ADD_REQUEST_SUCCESS";
export const ADD_REQUEST_FAIL = "ADD_REQUEST_FAIL";

export const EDIT_REQUEST_REQUEST = "EDIT_REQUEST_REQUEST";
export const EDIT_REQUEST_SUCCESS = "EDIT_REQUEST_SUCESS";
export const EDIT_REQUEST_FAIL = "EDIT_REQUEST_FAIL";

export const DELETE_REQUEST_REQUEST = "DELETE_REQUEST_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCESS";
export const DELETE_REQUEST_FAIL = "DELETE_REQUEST_FAIL";

export const CLEAR = "CLEAR";
