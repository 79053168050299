import {
    FETCH_EMAIL_REQUEST,
    FETCH_EMAIL_SUCCESS,
    FETCH_EMAIL_FAIL,
 
    DELETE_EMAIL_REQUEST,
    DELETE_EMAIL_SUCCESS,
    DELETE_EMAIL_FAIL,
    CLEAR
  } from "./EmailTypes";
  
  export const initialState = {
    loading: false,
    email: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const EmailReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_EMAIL_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_EMAIL_SUCCESS:
        return {
          loading: false,
          email: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_EMAIL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
        case DELETE_EMAIL_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_EMAIL_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_EMAIL_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default EmailReducer;
  