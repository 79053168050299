import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import userReducer from "./User/userReducer";
import authReducer from "./auth/authReducer";
import ContactReducer from "./Contact/ContactReducer";

import PromoReducer from "./Promocode/PromocodeReducer";
import EmailReducer from "./Email/EmailReducer";
import BillingReducer from "./Billing/BillingReducer";
import ShippingReducer from "./Shipping/ShippingReducer";
import CategoryReducer from "./Category/CategoryReducer";
import CareerReducer from "./Career/CareerReducer";
import BrandReducer from "./brand/brandReducer";
import ProductReducer from "./product/productReducer";
import OrderReducer from "./order/OrderReducer";
import StatReducer from "./stat/StatReducer";
import VacancyReducer1 from "./Vacancy1/vacancy1Reducer";
import PreownedProductsReducer from "./pre-o/PreownedProducts/PreownedProductsReducer";
import PreownedCategoryReducer from "./pre-o/preownedCategory/categoryReducer";
import PreownedSubCategoryReducer from "./pre-o/preownedSubCategory/subCategoryReducer";
import FeaturedProductsReducer from "./FeaturedProducts/FeaturedProductsReducer";
import FeaturedBrandReducer from "./FeaturedBrand/FeaturedBrandReducer"
import BookAppointmentReducer from "./BookAppointment/BookAppointmentReducer";
import NewsReducer from "./news/newsReducer";
import FavoriteReducer from "./favorites/favoritesReducer";
import CardReducer from "./Card/CardReducer";
import SubCategoryReducer from "./subCatergory/subCategoryReducer";
import SubSubCategoryReducer from "./subSubCategories/subSubCategoriesReducer";
import LocationReducer from "./location/locationReducer";
import GiftReducer from "./Gifts/giftReducer";
import RequestReducer from "./request/requestReducer";
import VideoReducer from "./video/videoReducer";
import ApplyReducer from "./apply/applyReducer";
import ShippingReducer1 from "./shippingApi/shippingReducer";
import ProductImageReducer from "./product/productImages/productImageReducer"
const middleware = [thunk];

const rootReducer = combineReducers({

  authReducer:authReducer,
  userReducer:userReducer,
  ContactReducer:ContactReducer,
  PromoReducer:PromoReducer,
  EmailReducer:EmailReducer,
  BillingReducer:BillingReducer,
  ShippingReducer:ShippingReducer,
  CategoryReducer:CategoryReducer,
  CareerReducer:CareerReducer,
  BrandReducer:BrandReducer,
  ProductReducer:ProductReducer,
  PreownedProductsReducer:PreownedProductsReducer,
  OrderReducer:OrderReducer,
  StatReducer:StatReducer,
  VacancyReducer1:VacancyReducer1,
  FeaturedProductsReducer:FeaturedProductsReducer,
  FeaturedBrandReducer:FeaturedBrandReducer,
  BookAppointmentReducer:BookAppointmentReducer,
  NewsReducer:NewsReducer,
  FavoriteReducer:FavoriteReducer,
  CardReducer:CardReducer,
  SubCategoryReducer:SubCategoryReducer,
  SubSubCategoryReducer:SubSubCategoryReducer,
  LocationReducer:LocationReducer,
  GiftReducer:GiftReducer,
  RequestReducer:RequestReducer,
  VideoReducer:VideoReducer,
  ApplyReducer:ApplyReducer,
  PreownedCategoryReducer:PreownedCategoryReducer,
  PreownedSubCategoryReducer:PreownedSubCategoryReducer,
  ShippingReducer1:ShippingReducer1,
  ProductImageReducer:ProductImageReducer,
});

const store = createStore(
  rootReducer,
  
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
