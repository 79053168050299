import {
    FETCH_FEATUREDPRODUCTS_REQUEST,
    FETCH_FEATUREDPRODUCTS_SUCCESS,
    FETCH_FEATUREDPRODUCTS_FAIL,
    ADD_FEATUREDPRODUCTS_REQUEST,
    ADD_FEATUREDPRODUCTS_SUCCESS,
    ADD_FEATUREDPRODUCTS_FAIL,
    EDIT_FEATUREDPRODUCTS_REQUEST,
    EDIT_FEATUREDPRODUCTS_SUCCESS,
    EDIT_FEATUREDPRODUCTS_FAIL,
    DELETE_FEATUREDPRODUCTS_REQUEST,
    DELETE_FEATUREDPRODUCTS_SUCCESS,
    DELETE_FEATUREDPRODUCTS_FAIL,
    CLEAR,

    ONE_FEATURED_PRODUCT_REQUEST,
    ONE_FEATURED_PRODUCT_SUCCESS,
    ONE_FEATURED_PRODUCT_FAIL,

    DELETE_ONE_FEATURED_PRODUCT_IMAGE_REQUEST,
    DELETE_ONE_FEATURED_PRODUCT_IMAGE_FAIL,
    DELETE_ONE_FEATURED_PRODUCT_IMAGE_SUCCESS,
  } from "./FeaturedProductsTypes";
  
  export const initialState = {
    loading: false,
    featuredProducts: [],
    error: "",

    addLoading: false,
    addError: "",

    editLoading: false,
    editError: "",

    deleteLoading: false,
    deleteError: "",

    oneProduct:{},
    loadingOneProduct:false,
    errorOneProduct:false,

  };
  
  const FeaturedProductsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_FEATUREDPRODUCTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_FEATUREDPRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          featuredProducts: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_FEATUREDPRODUCTS_FAIL:
        return {
          ...state,
          loading: false,
          error: action?.payload?.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_FEATUREDPRODUCTS_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_FEATUREDPRODUCTS_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: "",
        };
      case ADD_FEATUREDPRODUCTS_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_FEATUREDPRODUCTS_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_FEATUREDPRODUCTS_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: "",
        };
      case EDIT_FEATUREDPRODUCTS_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_FEATUREDPRODUCTS_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_FEATUREDPRODUCTS_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: "",
        };
      case DELETE_FEATUREDPRODUCTS_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
      case ONE_FEATURED_PRODUCT_REQUEST:
        return {
          ...state,
          loadingOneProduct:true,
          errorOneProduct:false,
        }
      case ONE_FEATURED_PRODUCT_SUCCESS:
        return {
          ...state,
          loadingOneProduct:false,
          oneProduct:action.payload,
          errorOneProduct:false,
        }

      case ONE_FEATURED_PRODUCT_FAIL:
        return {
          ...state,
          loadingOneProduct:false,
          errorOneProduct:action.payload,
        }
      case DELETE_ONE_FEATURED_PRODUCT_IMAGE_FAIL:
        return {
          ...state,
          loadingOneProduct:false,
          errorOneProduct:action.payload,
        }
      case DELETE_ONE_FEATURED_PRODUCT_IMAGE_REQUEST:
        return {
          ...state,
          loadingOneProduct:true,
          errorOneProduct:false,
        }
      case DELETE_ONE_FEATURED_PRODUCT_IMAGE_SUCCESS:
        return {
          ...state,
          loadingOneProduct:false,
          errorOneProduct:false,
        }

  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default FeaturedProductsReducer;
  