import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";

import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateApplicant, getIsAccepted } from "../../Redux/apply/applyAction";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
//utils
import isAuth from "../../Utils/isAuth";

import ReactDatatable from "@ashvin27/react-datatable";

const AcceptedApplicants = () => {
  const handleExportToExcel = () => {
    exportToExcel(records);
  };

  function exportToExcel(records) {
    if (!records || records.length === 0) {
      return;
    }
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter((entry, index) => index !== 0);
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as the first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "MasterClassTestimonial.xlsx");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, applies, error, addError, editError, deleteError } =
    useSelector((state) => state.ApplyReducer);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getIsAccepted());
  }, [dispatch]);

  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (applies?.data) {
      setRecords(applies?.data);
    }
  }, [applies]);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [cv, setCV] = useState();
  const [reason, setReason] = useState("");
  const [is_accepted, setIsAccepted] = useState("");
  const [id, setId] = useState("");

  const handleAcceptApplicant = (id, reason, is_accepted) => {
    dispatch(updateApplicant(id, reason, is_accepted));
    setId("");
    setReason("");
    setIsAccepted("");
  };

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      renderCell: (record) => (
        <div className="d-flex align-items-center p-2">
          <div className="ms-3">
            <div classname="mb-0">
              {record?.row?.first_name} {record?.row?.last_name}
            </div>
            <span className="text-muted">{record?.row?.email}</span>
          </div>
        </div>
      ),
    },

    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Job",
      flex: 1,

      renderCell: (record) => record?.row?.job.title,
    },

    {
      field: "cv",
      headerName: "CV",
      sortable: false,
      flex: 1,

      renderCell: (record) => (
        <a
          href={process.env.REACT_APP_API + "/apply/" + record?.row?.cv}
          download
        >
          CV
        </a>
      ),
    },
  ];

  return (
    <div>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Accepted Applicants</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Details
          </CardSubtitle>

          <DataGrid
            className="table"
            rows={records}
            columns={columns}
            loading={loading}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection={false}
            density="comfortable"
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default AcceptedApplicants;
