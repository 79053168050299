import {
    FETCH_STAT_REQUEST,
    FETCH_STAT_SUCCESS,
    FETCH_STAT_FAIL,
 
    DELETE_STAT_REQUEST,
    DELETE_STAT_SUCCESS,
    DELETE_STAT_FAIL,
    CLEAR
  } from "./StatType";
  
  export const initialState = {
    loading: false,
    stat: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const StatReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_STAT_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_STAT_SUCCESS:
        return {
          loading: false,
          stat: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_STAT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default StatReducer;
  