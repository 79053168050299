export const FETCH_NEWS_REQUEST = "FETCH_NEWS_REQUEST";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAIL = "FETCH_NEWS_FAIL";

export const ADD_NEWS_REQUEST = "ADD_NEWS_REQUEST";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_FAIL = "ADD_NEWS_FAIL";

export const EDIT_NEWS_REQUEST = "EDIT_NEWS_REQUEST";
export const EDIT_NEWS_SUCCESS = "EDIT_NEWS_SUCCESS";
export const EDIT_NEWS_FAIL = "EDIT_NEWS_FAIL";

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";

export const CLEAR = "CLEAR";
