export const FETCH_PREOWNEDPRODUCTS_REQUEST = "FETCH_PREOWNEDPRODUCTS_REQUEST";
export const FETCH_PREOWNEDPRODUCTS_SUCCESS = "FETCH_PREOWNEDPRODUCTS_SUCCESS";
export const FETCH_PREOWNEDPRODUCTS_FAIL = "FETCH_PREOWNEDPRODUCTS_FAIL";

export const ADD_PREOWNEDPRODUCTS_REQUEST = "ADD_PREOWNEDPRODUCTS_REQUEST";
export const ADD_PREOWNEDPRODUCTS_SUCCESS = "ADD_PREOWNEDPRODUCTS_SUCESS";
export const ADD_PREOWNEDPRODUCTS_FAIL = "ADD_PREOWNEDPRODUCTS_FAIL";

export const EDIT_PREOWNEDPRODUCTS_REQUEST = "EDIT_PREOWNEDPRODUCTS_REQUEST";
export const EDIT_PREOWNEDPRODUCTS_SUCCESS = "EDIT_PREOWNEDPRODUCTS_SUCESS";
export const EDIT_PREOWNEDPRODUCTS_FAIL = "EDIT_PREOWNEDPRODUCTS_FAIL";

export const DELETE_PREOWNEDPRODUCTS_REQUEST = "DELETE_PREOWNEDPRODUCTS_REQUEST";
export const DELETE_PREOWNEDPRODUCTS_SUCCESS = "DELETE_PREOWNEDPRODUCTS_SUCESS";
export const DELETE_PREOWNEDPRODUCTS_FAIL = "DELETE_PREOWNEDPRODUCTS_FAIL";


export const CLEAR = "CLEAR";