import jwt_decode from "jwt-decode";

//redux
import { logoutAdmin } from "../Redux/auth/authAction";
import { LOGIN_SUCCESS } from "../Redux/auth/authType";

//utils
import setAuthToken from "./setAuthToken";

const isAuth = (dispatch) => {
  const token = localStorage.jwtToken;
  if (token) {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      dispatch(logoutAdmin());
      setAuthToken(false);
      return false;
    }
    setAuthToken(token);
    dispatch({ type: LOGIN_SUCCESS });
    return true;
  }
  return false;
};

export default isAuth;
