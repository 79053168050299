import axios from "axios";
import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

//redux
import {
    FETCH_APPLY_REQUEST,
    FETCH_APPLY_SUCCESS,
    FETCH_APPLY_FAIL,
    FETCH_APPLYBYJOB_REQUEST,
    FETCH_APPLYBYJOB_SUCCESS,
    FETCH_APPLYBYJOB_FAIL,
    FETCH_ISACCEPTED_REQUEST,
    FETCH_ISACCEPTED_SUCCESS,
    FETCH_ISACCEPTED_FAIL,
    EDIT_APPLY_REQUEST,
    EDIT_APPLY_SUCCESS,
    EDIT_APPLY_FAIL,
    CLEAR,
} from "./applyType";

export const fetchRequest = () => {
    return {
        type: FETCH_APPLY_REQUEST,
    };
};

export const fetchSuccess = (applies) => {
    return {
        type: FETCH_APPLY_SUCCESS,
        payload: applies,
    };
};

export const fetchFail = (error) => {
    return {
        type: FETCH_APPLY_FAIL,
        payload: error,
    };
};  

export const fetchByJobRequest = () => {
    return {
        type: FETCH_APPLYBYJOB_REQUEST,
    };
};

export const fetchByJobSuccess = (applies) => {
    return {
        type: FETCH_APPLYBYJOB_SUCCESS,
        payload: applies,
    };
};

export const fetchByJobFail = (error) => {
    return {
        type: FETCH_APPLYBYJOB_FAIL,
        payload: error,
    };
};  


export const fetchIsAcceptedRequest = () => {
    return {
        type: FETCH_ISACCEPTED_REQUEST,
    };
};

export const fetchIsAcceptedSuccess = (applies) => {
    return {
        type: FETCH_ISACCEPTED_SUCCESS,
        payload: applies,
    };
};

export const fetchIsAcceptedFail = (error) => {
    return {
        type: FETCH_ISACCEPTED_FAIL,
        payload: error,
    };
};

export const editApplyRequest = () => {
    return {
      type: EDIT_APPLY_REQUEST,
    };
  };
  
  export const editApplySuccess = () => {
    return {
      type: EDIT_APPLY_SUCCESS,
    };
  };
  
  export const editApplyFail = (error) => {
    return {
      type: EDIT_APPLY_FAIL,
      payload: error,
    };
  };

export const clearSuccess = () => {
    return {
        type: CLEAR,
    };
};

export const getApply = () => async (dispatch) => {
    dispatch(fetchRequest());
    try {
        let url = process.env.REACT_APP_API + "/apply/getAll/admin";
        const response = await axios.get(url);
        dispatch(fetchSuccess(response.data));

    } catch (e) {
        dispatch(fetchFail(e.response));
    }
};


export const getApplyByJob = (id) => async (dispatch) => {
    dispatch(fetchByJobRequest());
    try {
        let url = process.env.REACT_APP_API + "/apply/job/"+id;
        const response = await axios.get(url);
        dispatch(fetchByJobSuccess(response.data));
    } catch (e) {
        dispatch(fetchByJobFail(e.response));
    }
};

export const getIsAccepted = () => async (dispatch) => {
    dispatch(fetchIsAcceptedRequest());
    try {
        let url = process.env.REACT_APP_API + "/apply/isAccepted/true";
        const response = await axios.get(url);
        dispatch(fetchIsAcceptedSuccess(response.data));
    } catch (e) {
        dispatch(fetchIsAcceptedFail(e.response));
    }
};

export const updateApplicant =
  (id, is_accepted) => async (dispatch) => {
    dispatch(editApplyRequest());
    console.log("hi",is_accepted)
    try {
      await axios.patch(process.env.REACT_APP_API + "/apply/" + id, {
        is_accepted
      });
      dispatch(editApplySuccess());
      toast.success("Applicant Accepted/Rejected Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: "colored",
      });
      dispatch(getApply());
    } catch (e) {
      dispatch(editApplyFail(e.response.data));
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: "colored",
      });
    }
  };

export const clear = () => (dispatch) => {
    dispatch(clearSuccess());
    
};