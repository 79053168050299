export const FETCH_LOCATION_REQUEST = "FETCH_LOCATION_REQUEST";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_FAIL = "FETCH_LOCATION_FAIL";

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCESS";
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL";

export const EDIT_LOCATION_REQUEST = "EDIT_LOCATION_REQUEST";
export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCESS";
export const EDIT_LOCATION_FAIL = "EDIT_LOCATION_FAIL";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCESS";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";


export const CLEAR = "CLEAR";