export const FETCH_FAVORITE_REQUEST = "FETCH_FAVORITE_REQUEST";
export const FETCH_FAVORITE_SUCCESS = "FETCH_FAVORITE_SUCCESS";
export const FETCH_FAVORITE_FAIL = "FETCH_FAVORITE_FAIL";

export const ADD_FAVORITE_REQUEST = "ADD_FAVORITE_REQUEST";
export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS";
export const ADD_FAVORITE_FAIL = "ADD_FAVORITE_FAIL";

export const EDIT_FAVORITE_REQUEST = "EDIT_FAVORITE_REQUEST";
export const EDIT_FAVORITE_SUCCESS = "EDIT_FAVORITE_SUCESS";
export const EDIT_FAVORITE_FAIL = "EDIT_FAVORITE_FAIL";

export const DELETE_FAVORITE_REQUEST = "DELETE_FAVORITE_REQUEST";
export const DELETE_FAVORITE_SUCCESS = "DELETE_FAVORITE_SUCESS";
export const DELETE_FAVORITE_FAIL = "DELETE_FAVORITE_FAIL";

export const CLEAR = "CLEAR";
