export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const FETCH_FEATURED_PRODUCT_REQUEST = "FETCH_FEATURED_PRODUCT_REQUEST";
export const FETCH_FEATURED_PRODUCT_SUCCESS = "FETCH_FEATURED_PRODUCT_SUCCESS";
export const FETCH_FEATURED_PRODUCT_FAIL = "FETCH_FEATURED_PRODUCT_FAIL";

export const CLEAR = "CLEAR";