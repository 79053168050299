import {
    FETCH_BILLING_REQUEST,
    FETCH_BILLING_SUCCESS,
    FETCH_BILLING_FAIL,
  
    EDIT_BILLING_REQUEST,
    EDIT_BILLING_SUCCESS,
    EDIT_BILLING_FAIL,
  
    DELETE_BILLING_REQUEST,
    DELETE_BILLING_SUCCESS,
    DELETE_BILLING_FAIL,

  
    CLEAR
  } from "./BillingType";
  
  export const initialState = {
    loading: false,
    billing: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const BillingReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_BILLING_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_BILLING_SUCCESS:
        return {
          loading: false,
          billing: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_BILLING_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
        
    
        case EDIT_BILLING_REQUEST:
          return {
            ...state,
            editLoading: true,
            editError: "",
          };
        case EDIT_BILLING_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editError: ""
          };
        case EDIT_BILLING_FAIL:
          return {
            ...state,
            editLoading: false,
            editError: action.payload,
          };
    
        case DELETE_BILLING_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_BILLING_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_BILLING_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
    
  
    
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default BillingReducer;
  