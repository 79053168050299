export const FETCH_FEATUREDPRODUCTS_REQUEST = "FETCH_FEATUREDPRODUCTS_REQUEST";
export const FETCH_FEATUREDPRODUCTS_SUCCESS = "FETCH_FEATUREDPRODUCTS_SUCCESS";
export const FETCH_FEATUREDPRODUCTS_FAIL = "FETCH_FEATUREDPRODUCTS_FAIL";

export const ADD_FEATUREDPRODUCTS_REQUEST = "ADD_FEATUREDPRODUCTS_REQUEST";
export const ADD_FEATUREDPRODUCTS_SUCCESS = "ADD_FEATUREDPRODUCTS_SUCCESS";
export const ADD_FEATUREDPRODUCTS_FAIL = "ADD_FEATUREDPRODUCTS_FAIL";

export const EDIT_FEATUREDPRODUCTS_REQUEST = "EDIT_FEATUREDPRODUCTS_REQUEST";
export const EDIT_FEATUREDPRODUCTS_SUCCESS = "EDIT_FEATUREDPRODUCTS_SUCESS";
export const EDIT_FEATUREDPRODUCTS_FAIL = "EDIT_FEATUREDPRODUCTS_FAIL";

export const DELETE_FEATUREDPRODUCTS_REQUEST = "DELETE_FEATUREDPRODUCTS_REQUEST";
export const DELETE_FEATUREDPRODUCTS_SUCCESS = "DELETE_FEATUREDPRODUCTS_SUCESS";
export const DELETE_FEATUREDPRODUCTS_FAIL = "DELETE_FEATUREDPRODUCTS_FAIL";

export const ONE_FEATURED_PRODUCT_REQUEST = "ONE_FEATURED_PRODUCT_REQUEST";
export const ONE_FEATURED_PRODUCT_SUCCESS = "ONE_FEATURED_PRODUCT_SUCCESS";
export const ONE_FEATURED_PRODUCT_FAIL = "ONE_FEATURED_PRODUCT_FAIL";

export const DELETE_ONE_FEATURED_PRODUCT_IMAGE_REQUEST = "DELETE_ONE_FEATURED_PRODUCT_IMAGE"
export const DELETE_ONE_FEATURED_PRODUCT_IMAGE_SUCCESS = "DELETE_ONE_FEATURED_PRODUCT_IMAGE_SUCCESS"
export const DELETE_ONE_FEATURED_PRODUCT_IMAGE_FAIL = "DELETE_ONE_FEATURED_PRODUCT_IMAGE_FAIL"

export const ADD_IMAGES_FOR_ONE_FEATURED_PRODUCT_REQUEST = "ADD_IMAGES_FOR_ONE_FEATURED_PRODUCT_REQUEST"
export const ADD_IMAGES_FOR_ONE_FEATURED_PRODUCT_SUCCESS = "ADD_IMAGES_FOR_ONE_FEATURED_PRODUCT_SUCCESS"
export const ADD_IMAGES_FOR_ONE_FEATURED_PRODUCT_FAIL = "ADD_IMAGES_FOR_ONE_FEATURED_PRODUCT_FAIL"

export const CLEAR = "CLEAR";
