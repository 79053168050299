export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAIL = "FETCH_ORDER_FAIL";

export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const EDIT_ORDER_REQUEST = "EDIT_ORDER_REQUEST";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCESS";
export const EDIT_ORDER_FAIL = "EDIT_ORDER_FAIL";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";


export const CLEAR = "CLEAR";