import axios from "axios";

//redux
import {
    FETCH_GIFT_REQUEST,
    FETCH_GIFT_SUCCESS,
    FETCH_GIFT_FAIL,

    CLEAR,
} from "./giftType";

export const fetchRequest = () => {
    return {
        type: FETCH_GIFT_REQUEST,
    };
};

export const fetchSuccess = (gifts) => {
    return {
        type: FETCH_GIFT_SUCCESS,
        payload: gifts,
    };
};

export const fetchFail = (error) => {
    return {
        type: FETCH_GIFT_FAIL,
        payload: error,
    };
};  

export const clearSuccess = () => {
    return {
        type: CLEAR,
    };
};

export const getGift = () => async (dispatch) => {
    dispatch(fetchRequest());
    try {
        let url = process.env.REACT_APP_API + "/gifts";
        const response = await axios.get(url);
        dispatch(fetchSuccess(response.data));
    } catch (e) {
        dispatch(fetchFail(e.response));
    }
};




export const clear = () => (dispatch) => {
    dispatch(clearSuccess());
};