import {
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAIL,

    
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,

  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAIL,

  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,

 

  CLEAR
} from "./OrderTypes";

export const initialState = {
  loading: false,
  orders: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ORDER_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

      case ADD_ORDER_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_ORDER_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: ""
        };
      case ADD_ORDER_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_ORDER_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_ORDER_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_ORDER_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_ORDER_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_ORDER_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: ""
        };
      case DELETE_ORDER_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
  

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};
export default OrderReducer;
