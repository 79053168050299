import axios from "axios";

//utils
import setAuthToken from "../../Utils/setAuthToken";
  import {REACT_APP_API} from "./../../config"
//redux
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGOUT,

} from "./authType";

export const loginError = (error) => {
  return {
    type: LOGIN_FAIL,
    payload: error,
  };
};

export const loginLoading = () => {
  return {
    type: LOGIN_LOADING,
  };
};

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,

  
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};



export const loginAdmin = (email, password) => async (dispatch) => {
 
  dispatch(loginLoading());
  try {
    const user = await axios.post(REACT_APP_API + "/login_admin", {
      email,
      password,
    });
    console.log(REACT_APP_API
    )
    console.log(user)
    localStorage.setItem("jwtToken", user?.data?.token);
    localStorage.setItem("id", user?.data?.user?.id);
    localStorage.setItem("email",user?.data?.user?.email);

  
   
    dispatch(loginSuccess());

  } catch (e) {
    dispatch(loginError(e.response.data));
  }
};


export const logoutAdmin = () => (dispatch) => {
  localStorage.removeItem("id");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("email");

  
  setAuthToken(false);
  dispatch(logout());
  window.location.href = "/";
};
