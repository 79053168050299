export const FETCH_BRAND_REQUEST = "FETCH_BRAND_REQUEST";
export const FETCH_BRAND_SUCCESS = "FETCH_BRAND_SUCCESS";
export const FETCH_BRAND_FAIL = "FETCH_BRAND_FAIL";

export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCESS";
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL";

export const EDIT_BRAND_REQUEST = "EDIT_BRAND_REQUEST";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCESS";
export const EDIT_BRAND_FAIL = "EDIT_BRAND_FAIL";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCESS";
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL";


export const CLEAR = "CLEAR";