import {
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAIL,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,
  
    EDIT_USER_REQUEST,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAIL,
  
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
  
   
  
    CLEAR
  } from "./userType";
  
  export const initialState = {
    loading: false,
    users: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_USER_SUCCESS:
        return {
          loading: false,
          users: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_USER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
    
        case ADD_USER_REQUEST:
          return {
            ...state,
            addLoading: true,
            addError: "",
          };
        case ADD_USER_SUCCESS:
          return {
            ...state,
            addLoading: false,
            addError: ""
          };
        case ADD_USER_FAIL:
          return {
            ...state,
            addLoading: false,
            addError: action.payload,
          };
    
        case EDIT_USER_REQUEST:
          return {
            ...state,
            editLoading: true,
            editError: "",
          };
        case EDIT_USER_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editError: ""
          };
        case EDIT_USER_FAIL:
          return {
            ...state,
            editLoading: false,
            editError: action.payload,
          };
    
        case DELETE_USER_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_USER_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_USER_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
    
  
  

      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default userReducer;
