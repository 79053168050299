export const FETCH_PRODUCTIMAGE_REQUEST = "FETCH_PRODUCTIMAGE_REQUEST";
export const FETCH_PRODUCTIMAGE_SUCCESS = "FETCH_PRODUCTIMAGE_SUCCESS";
export const FETCH_PRODUCTIMAGE_FAIL = "FETCH_PRODUCTIMAGE_FAIL";

export const ADD_PRODUCTIMAGE_REQUEST = "ADD_PRODUCTIMAGE_REQUEST";
export const ADD_PRODUCTIMAGE_SUCCESS = "ADD_PRODUCTIMAGE_SUCESS";
export const ADD_PRODUCTIMAGE_FAIL = "ADD_PRODUCTIMAGE_FAIL";

export const DELETE_PRODUCTIMAGE_REQUEST = "DELETE_PRODUCTIMAGE_REQUEST";
export const DELETE_PRODUCTIMAGE_SUCCESS = "DELETE_PRODUCTIMAGE_SUCESS";
export const DELETE_PRODUCTIMAGE_FAIL = "DELETE_PRODUCTIMAGE_FAIL";
export const CLEAR = "CLEAR";
