import {
  FETCH_APPLY_REQUEST,
  FETCH_APPLY_SUCCESS,
  FETCH_APPLY_FAIL,
  EDIT_APPLY_REQUEST,
  EDIT_APPLY_SUCCESS,
  EDIT_APPLY_FAIL,
  FETCH_ISACCEPTED_REQUEST,
  FETCH_ISACCEPTED_SUCCESS,
  FETCH_ISACCEPTED_FAIL,
  FETCH_APPLYBYJOB_REQUEST,
  FETCH_APPLYBYJOB_SUCCESS,
  FETCH_APPLYBYJOB_FAIL,
  CLEAR,
} from "./applyType";

export const initialState = {
  loading: false,
  applies: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const ApplyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_APPLY_SUCCESS:
      return {
        loading: false,
        applies: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_APPLY_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload?.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case FETCH_APPLYBYJOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_APPLYBYJOB_SUCCESS:
      return {
        loading: false,
        applies: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_APPLYBYJOB_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

      case FETCH_ISACCEPTED_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_ISACCEPTED_SUCCESS:
        return {
          loading: false,
          applies: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_ISACCEPTED_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };


    case EDIT_APPLY_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_APPLY_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: "",
      };
    case EDIT_APPLY_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};
export default ApplyReducer;
