export const FETCH_APPOINTMENT_REQUEST = "FETCH_APPOINTMENT_REQUEST";
export const FETCH_APPOINTMENT_SUCCESS = "FETCH_APPOINTMENT_SUCCESS";
export const FETCH_APPOINTMENT_FAIL = "FETCH_APPOINTMENT_FAIL";

export const ADD_APPOINTMENT_REQUEST = "ADD_APPOINTMENT_REQUEST";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_FAIL = "ADD_APPOINTMENT_FAIL";

export const EDIT_APPOINTMENT_REQUEST = "EDIT_APPOINTMENT_REQUEST";
export const EDIT_APPOINTMENT_SUCCESS = "EDIT_APPOINTMENT_SUCESS";
export const EDIT_APPOINTMENT_FAIL = "EDIT_APPOINTMENT_FAIL";

export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCESS";
export const DELETE_APPOINTMENT_FAIL = "DELETE_APPOINTMENT_FAIL";

export const CLEAR = "CLEAR";
