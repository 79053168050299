export const FETCH_SUBSUBCATEGORY_REQUEST = "FETCH_SUBSUBCATEGORY_REQUEST";
export const FETCH_SUBSUBCATEGORY_SUCCESS = "FETCH_SUBSUBCATEGORY_SUCCESS";
export const FETCH_SUBSUBCATEGORY_FAIL = "FETCH_SUBSUBCATEGORY_FAIL";

export const ADD_SUBSUBCATEGORY_REQUEST = "ADD_SUBSUBCATEGORY_REQUEST";
export const ADD_SUBSUBCATEGORY_SUCCESS = "ADD_SUBSUBCATEGORY_SUCESS";
export const ADD_SUBSUBCATEGORY_FAIL = "ADD_SUBSUBCATEGORY_FAIL";

export const EDIT_SUBSUBCATEGORY_REQUEST = "EDIT_SUBSUBCATEGORY_REQUEST";
export const EDIT_SUBSUBCATEGORY_SUCCESS = "EDIT_SUBSUBCATEGORY_SUCESS";
export const EDIT_SUBSUBCATEGORY_FAIL = "EDIT_SUBSUBCATEGORY_FAIL";

export const DELETE_SUBSUBCATEGORY_REQUEST = "DELETE_SUBSUBCATEGORY_REQUEST";
export const DELETE_SUBSUBCATEGORY_SUCCESS = "DELETE_SUBSUBCATEGORY_SUCESS";
export const DELETE_SUBSUBCATEGORY_FAIL = "DELETE_SUBSUBCATEGORY_FAIL";


export const CLEAR = "CLEAR";