import {
    FETCH_VIDEO_REQUEST,
    FETCH_VIDEO_SUCCESS,
    FETCH_VIDEO_FAIL,
  
    EDIT_VIDEO_REQUEST,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAIL,

    CLEAR
  } from "./videoType";
  
  export const initialState = {
    loading: false,
    videos: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const VideoReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_VIDEO_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_VIDEO_SUCCESS:
        return {
          loading: false,
          videos: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_VIDEO_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
        case EDIT_VIDEO_REQUEST:
          return {
            ...state,
            editLoading: true,
            editError: "",
          };
        case EDIT_VIDEO_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editError: ""
          };
        case EDIT_VIDEO_FAIL:
          return {
            ...state,
            editLoading: false,
            editError: action.payload,
          };

      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default VideoReducer;
  