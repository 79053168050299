import {
  FETCH_SUBCATEGORY_REQUEST,
  FETCH_SUBCATEGORY_SUCCESS,
  FETCH_SUBCATEGORY_FAIL,
  FETCH_SUBCATEGORYBYCATEGORYID_REQUEST,
  FETCH_SUBCATEGORYBYCATEGORYID_SUCCESS,
  FETCH_SUBCATEGORYBYCATEGORYID_FAIL,
  ADD_SUBCATEGORY_REQUEST,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_FAIL,
  EDIT_SUBCATEGORY_REQUEST,
  EDIT_SUBCATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY_REQUEST,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
  CLEAR,
} from "./subCategoryTypes";

export const initialState = {
  loading: false,
  preownedSubCategories: [],
  preownedSubCategoriesbycategory: [],

  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const PreownedSubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_SUBCATEGORY_SUCCESS:
      return {
        loading: false,
        preownedSubCategories: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_SUBCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case FETCH_SUBCATEGORYBYCATEGORYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_SUBCATEGORYBYCATEGORYID_SUCCESS:
      return {
        loading: false,
        preownedSubCategoriesbycategory: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_SUBCATEGORYBYCATEGORYID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_SUBCATEGORY_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: "",
      };
    case ADD_SUBCATEGORY_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_SUBCATEGORY_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: "",
      };
    case EDIT_SUBCATEGORY_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: "",
      };
    case DELETE_SUBCATEGORY_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};
export default PreownedSubCategoryReducer;
