export const FETCH_FEATUREDBRAND_REQUEST = "FETCH_FEATUREDBRAND_REQUEST";
export const FETCH_FEATUREDBRAND_SUCCESS = "FETCH_FEATUREDBRAND_SUCCESS";
export const FETCH_FEATUREDBRAND_FAIL = "FETCH_FEATUREDBRAND_FAIL";

export const ADD_FEATUREDBRAND_REQUEST = "ADD_FEATUREDBRAND_REQUEST";
export const ADD_FEATUREDBRAND_SUCCESS = "ADD_FEATUREDBRAND_SUCCESS";
export const ADD_FEATUREDBRAND_FAIL = "ADD_FEATUREDBRAND_FAIL";

export const EDIT_FEATUREDBRAND_REQUEST = "EDIT_FEATUREDBRAND_REQUEST";
export const EDIT_FEATUREDBRAND_SUCCESS = "EDIT_FEATUREDBRAND_SUCCESS";
export const EDIT_FEATUREDBRAND_FAIL = "EDIT_FEATUREDBRAND_FAIL";

export const DELETE_FEATUREDBRAND_REQUEST = "DELETE_FEATUREDBRAND_REQUEST";
export const DELETE_FEATUREDBRAND_SUCCESS = "DELETE_FEATUREDBRAND_SUCCESS";
export const DELETE_FEATUREDBRAND_FAIL = "DELETE_FEATUREDBRAND_FAIL";

export const CHANGE_FEATUREDBRAND_REQUEST = "CHANGE_FEATUREDBRAND_REQUEST";
export const CHANGE_FEATUREDBRAND_SUCCESS = "CHANGE_FEATUREDBRAND_SUCCESS";
export const CHANGE_FEATUREDBRAND_FAIL = "CHANGE_FEATUREDBRAND_FAI";


export const ONE_FEATURED_BRAND_REQUEST = "ONE_FEATURED_BRAND_REQUEST";
export const ONE_FEATURED_BRAND_SUCCESS = "ONE_FEATURED_BRAND_SUCCESS";
export const ONE_FEATURED_BRAND_FAIL = "ONE_FEATURED_BRAND_FAIL";


export const DELETE_ONE_FEATURED_BRAND_IMAGE_REQUEST = "DELETE_ONE_FEATURED_BRAND_IMAGE"
export const DELETE_ONE_FEATURED_BRAND_IMAGE_SUCCESS = "DELETE_ONE_FEATURED_BRAND_IMAGE_SUCCESS"
export const DELETE_ONE_FEATURED_BRAND_IMAGE_FAIL = "DELETE_ONE_FEATURED_BRAND_IMAGE_FAIL"

export const ADD_IMAGES_FOR_ONE_FEATURED_BRAND_REQUEST = "ADD_IMAGES_FOR_ONE_FEATURED_BRAND_REQUEST"
export const ADD_IMAGES_FOR_ONE_FEATURED_BRAND_SUCCESS = "ADD_IMAGES_FOR_ONE_FEATURED_BRAND_SUCCESS"
export const ADD_IMAGES_FOR_ONE_FEATURED_BRAND_FAIL = "ADD_IMAGES_FOR_ONE_FEATURED_BRAND_FAIL"

export const CLEAR = "CLEAR";
