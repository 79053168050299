import {
    FETCH_LOCATION_REQUEST,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_FAIL,
  
      
    ADD_LOCATION_REQUEST,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAIL,
  
    EDIT_LOCATION_REQUEST,
    EDIT_LOCATION_SUCCESS,
    EDIT_LOCATION_FAIL,
  
    DELETE_LOCATION_REQUEST,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_FAIL,
  
   
  
    CLEAR
  } from "./locationType";
  
  export const initialState = {
    loading: false,
    locations: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const LocationReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_LOCATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_LOCATION_SUCCESS:
        return {
          loading: false,
          locations: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_LOCATION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
        case ADD_LOCATION_REQUEST:
          return {
            ...state,
            addLoading: true,
            addError: "",
          };
        case ADD_LOCATION_SUCCESS:
          return {
            ...state,
            addLoading: false,
            addError: ""
          };
        case ADD_LOCATION_FAIL:
          return {
            ...state,
            addLoading: false,
            addError: action.payload,
          };
    
        case EDIT_LOCATION_REQUEST:
          return {
            ...state,
            editLoading: true,
            editError: "",
          };
        case EDIT_LOCATION_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editError: ""
          };
        case EDIT_LOCATION_FAIL:
          return {
            ...state,
            editLoading: false,
            editError: action.payload,
          };
    
        case DELETE_LOCATION_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_LOCATION_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_LOCATION_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
    
    
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default LocationReducer;
  