export const FETCH_SUBCATEGORY_REQUEST = "FETCH_SUBCATEGORY_REQUEST";
export const FETCH_SUBCATEGORY_SUCCESS = "FETCH_SUBCATEGORY_SUCCESS";
export const FETCH_SUBCATEGORY_FAIL = "FETCH_SUBCATEGORY_FAIL";

export const FETCH_SUBCATEGORYBYCATEGORYID_REQUEST =
  "FETCH_SUBCATEGORYBYCATEGORYID_REQUEST";
export const FETCH_SUBCATEGORYBYCATEGORYID_SUCCESS =
  "FETCH_SUBCATEGORYBYCATEGORYID_SUCCESS";
export const FETCH_SUBCATEGORYBYCATEGORYID_FAIL =
  "FETCH_SUBCATEGORYBYCATEGORYID_FAIL";

export const ADD_SUBCATEGORY_REQUEST = "ADD_SUBCATEGORY_REQUEST";
export const ADD_SUBCATEGORY_SUCCESS = "ADD_SUBCATEGORY_SUCESS";
export const ADD_SUBCATEGORY_FAIL = "ADD_SUBCATEGORY_FAIL";

export const EDIT_SUBCATEGORY_REQUEST = "EDIT_SUBCATEGORY_REQUEST";
export const EDIT_SUBCATEGORY_SUCCESS = "EDIT_SUBCATEGORY_SUCESS";
export const EDIT_SUBCATEGORY_FAIL = "EDIT_SUBCATEGORY_FAIL";

export const DELETE_SUBCATEGORY_REQUEST = "DELETE_SUBCATEGORY_REQUEST";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCESS";
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL";

export const CLEAR = "CLEAR";
