//redux
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGOUT,
  
} from "./authType";

const initialState = {
  isAuthenticated: false,
  loading: false,
  error: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: "",
      };
   
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: "",
      };

    default:
      return state;
  }
}
