import {
    FETCH_FAVORITE_REQUEST,
    FETCH_FAVORITE_SUCCESS,
    FETCH_FAVORITE_FAIL,
    ADD_FAVORITE_REQUEST,
    ADD_FAVORITE_SUCCESS,
    ADD_FAVORITE_FAIL,
    DELETE_FAVORITE_REQUEST,
    DELETE_FAVORITE_SUCCESS,
    DELETE_FAVORITE_FAIL,
    CLEAR,
  } from "./favoritesTypes";
  
  export const initialState = {
    loading: false,
    favorites: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const FavoriteReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_FAVORITE_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_FAVORITE_SUCCESS:
        return {
          ...state,
          loading: false,
          favorites: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_FAVORITE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_FAVORITE_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_FAVORITE_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: "",
        };
      case ADD_FAVORITE_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case DELETE_FAVORITE_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_FAVORITE_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: "",
        };
      case DELETE_FAVORITE_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default FavoriteReducer;
  