export const FETCH_APPLY_REQUEST = "FETCH_APPLY_REQUEST";
export const FETCH_APPLY_SUCCESS = "FETCH_APPLY_SUCCESS";
export const FETCH_APPLY_FAIL = "FETCH_APPLY_FAIL";

export const FETCH_APPLYBYJOB_REQUEST = "FETCH_APPLYBYJOB_REQUEST";
export const FETCH_APPLYBYJOB_SUCCESS = "FETCH_APPLYBYJOB_SUCCESS";
export const FETCH_APPLYBYJOB_FAIL = "FETCH_APPLYBYJOB_FAIL";

export const FETCH_ISACCEPTED_REQUEST = "FETCH_ISACCEPTED_REQUEST";
export const FETCH_ISACCEPTED_SUCCESS = "FETCH_ISACCEPTED_SUCCESS";
export const FETCH_ISACCEPTED_FAIL = "FETCH_ISACCEPTED_FAIL";

export const EDIT_APPLY_REQUEST = "EDIT_APPLY_REQUEST";
export const EDIT_APPLY_SUCCESS = "EDIT_APPLY_SUCESS";
export const EDIT_APPLY_FAIL = "EDIT_APPLY_FAIL";

export const CLEAR = "CLEAR";