export const FETCH_PROMO_REQUEST = "FETCH_PROMO_REQUEST";
export const FETCH_PROMO_SUCCESS = "FETCH_PROMO_SUCCESS";
export const FETCH_PROMO_FAIL = "FETCH_PROMO_FAIL";

export const ADD_PROMO_REQUEST = "ADD_PROMO_REQUEST";
export const ADD_PROMO_SUCCESS = "ADD_PROMO_SUCESS";
export const ADD_PROMO_FAIL = "ADD_PROMO_FAIL";

export const EDIT_PROMO_REQUEST = "EDIT_PROMO_REQUEST";
export const EDIT_PROMO_SUCCESS = "EDIT_PROMO_SUCESS";
export const EDIT_PROMO_FAIL = "EDIT_PROMO_FAIL";

export const DELETE_PROMO_REQUEST = "DELETE_PROMO_REQUEST";
export const DELETE_PROMO_SUCCESS = "DELETE_PROMO_SUCESS";
export const DELETE_PROMO_FAIL = "DELETE_PROMO_FAIL";


export const CLEAR = "CLEAR";