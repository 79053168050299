import {
    FETCH_PREOWNEDCATEGORY_REQUEST,
    FETCH_PREOWNEDCATEGORY_SUCCESS,
    FETCH_PREOWNEDCATEGORY_FAIL,
  
      
    ADD_PREOWNEDCATEGORY_REQUEST,
    ADD_PREOWNEDCATEGORY_SUCCESS,
    ADD_PREOWNEDCATEGORY_FAIL,
  
    EDIT_PREOWNEDCATEGORY_REQUEST,
    EDIT_PREOWNEDCATEGORY_SUCCESS,
    EDIT_PREOWNEDCATEGORY_FAIL,
  
    DELETE_PREOWNEDCATEGORY_REQUEST,
    DELETE_PREOWNEDCATEGORY_SUCCESS,
    DELETE_PREOWNEDCATEGORY_FAIL,
  
   
  
    CLEAR
  } from "./categoryTypes";
  
  export const initialState = {
    loading: false,
    preownedCategories: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const PreownedCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_PREOWNEDCATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_PREOWNEDCATEGORY_SUCCESS:
        return {
          loading: false,
          preownedCategories: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_PREOWNEDCATEGORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
        case ADD_PREOWNEDCATEGORY_REQUEST:
          return {
            ...state,
            addLoading: true,
            addError: "",
          };
        case ADD_PREOWNEDCATEGORY_SUCCESS:
          return {
            ...state,
            addLoading: false,
            addError: ""
          };
        case ADD_PREOWNEDCATEGORY_FAIL:
          return {
            ...state,
            addLoading: false,
            addError: action.payload,
          };
    
        case EDIT_PREOWNEDCATEGORY_REQUEST:
          return {
            ...state,
            editLoading: true,
            editError: "",
          };
        case EDIT_PREOWNEDCATEGORY_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editError: ""
          };
        case EDIT_PREOWNEDCATEGORY_FAIL:
          return {
            ...state,
            editLoading: false,
            editError: action.payload,
          };
    
        case DELETE_PREOWNEDCATEGORY_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_PREOWNEDCATEGORY_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_PREOWNEDCATEGORY_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
    
    
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default PreownedCategoryReducer;
  