export const FETCH_BILLING_REQUEST = "FETCH_BILLING_REQUEST";
export const FETCH_BILLING_SUCCESS = "FETCH_BILLING_SUCCESS";
export const FETCH_BILLING_FAIL = "FETCH_BILLING_FAIL";

export const EDIT_BILLING_REQUEST = "EDIT_BILLING_REQUEST";
export const EDIT_BILLING_SUCCESS = "EDIT_BILLING_SUCESS";
export const EDIT_BILLING_FAIL = "EDIT_BILLING_FAIL";

export const DELETE_BILLING_REQUEST = "DELETE_BILLING_REQUEST";
export const DELETE_BILLING_SUCCESS = "DELETE_BILLING_SUCESS";
export const DELETE_BILLING_FAIL = "DELETE_BILLING_FAIL";


export const CLEAR = "CLEAR";
