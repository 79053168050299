import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Gift from "../Pages/Gifts/gifts.js";
import AcceptedApplicants from "../Pages/is_accepted/is_accepted.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Login = lazy(() => import("../Pages/Login/Login.js"));
const Dashbaord = lazy(() => import("../Pages/Dashbaord/Dashboard.js"));
const User = lazy(() => import("../Pages/user/User.js"));
const Contact = lazy(() => import("../Pages/Contact/Contact.js"));
const News = lazy(()=> import("../Pages/news/news.js"))
const PromoCode = lazy(() => import("../Pages/PromoCode/Promocode.js"));
const Email = lazy(() => import("../Pages/Email/Email.js"));
const Category = lazy(() => import("../Pages/Category/Category.js"));
const Shipping = lazy(() => import("../Pages/Shipping/Shipping.js"));
const Billing = lazy(() => import("../Pages/Billing/Billing.js"));
const Career = lazy(() => import("../Pages/Career/Career.js"));
const Brand = lazy(() => import("../Pages/Brand/Brand.js"));
const Product = lazy(() => import("../Pages//Product/Product.js"));
const FeaturedProduct = lazy(() => import("../Pages/FeaturedProducts/FeaturedProducts.js"))
const PreownedProducts = lazy(() => import("../Pages/Pre-o/PreownedProducts/PreownedProducts.js"));
const PreownedCategory = lazy(() => import("../Pages/Pre-o/Category/category.js"));
const PreownedSubCategory = lazy(() => import("../Pages/Pre-o/subCategory/subCategory.js"));
const FeaturedBrand = lazy(() => import("../Pages/FeaturedBrands/FeaturedBrands.js"))
const Appointment = lazy(() => import("../Pages/Appointments/Appointments.js"))
const Order = lazy(() => import("../Pages/Order/Order.js"));
const Vacancy = lazy(() => import("../Pages/Vacancy/vacancy.js"));
const Favorite = lazy(() => import("../Pages/Favorite/Favorite.js"));
const Cards1 = lazy(() => import("../Pages/Cards/Cards.js"));
const SubCategory = lazy(() => import("../Pages/SubCategory/subCategory.js"));
const Location = lazy(() => import("../Pages/Locations/location.js"));
const SubSubCategory = lazy(() => import("../Pages/sub_subCategory/sub_subCategory.js"));
const Request = lazy(() => import("../Pages/Request/request.js"));
const Video = lazy(() => import("../Pages/video/video.js"));
const Apply = lazy(() => import("../Pages/Apply/apply.js"));
const Applicants = lazy(() => import("../Pages/applicants/applicants.js"));
const Shipping1 = lazy(()=>import("../Pages/ShippingApi/shipping.js"));


const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/dashboard", exact: true, element: <Dashbaord /> },
      { path: "/user", exact: true, element: <User /> },
      { path: "/contact", exact: true, element: <Contact /> },
      { path: "/prom", exact: true, element: <PromoCode /> },
      { path: "/email", exact: true, element: <Email /> },
      { path: "/cat", exact: true, element: <Category /> },
      { path: "/ship", exact: true, element: <Shipping /> },
      { path: "/bill", exact: true, element: <Billing /> },
      { path: "/Vacancy", exact: true, element: <Vacancy /> },
      { path: "/career", exact: true, element: <Career /> },
      { path: "/product", exact: true, element: <Product /> },
      { path: "/PreownedProducts", exact: true, element: <PreownedProducts /> },
      { path: "/PreownedCategories", exact: true, element: <PreownedCategory /> },
      { path: "/PreownedSubCategories", exact: true, element: <PreownedSubCategory /> },
      { path: "/Featured_Product", exact: true, element: <FeaturedProduct /> },
      { path: "/order", exact: true, element: <Order /> },
      { path: "/BookAppointment", exact: true, element: <Appointment /> },
      { path: "/brand", exact: true, element: <Brand /> },
      { path: "/featured_Brand", exact: true, element: <FeaturedBrand /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
      { path: "/news", exact: true, element: <News /> },
      { path: "/favorite", exact: true, element: <Favorite /> },
      { path: "/card", exact: true, element: <Cards1 /> },
      { path: "/subCategory/:cat_Id", exact: true, element: <SubCategory /> },
      { path: "/sub_subCategory/:sub_id", exact: true, element: <SubSubCategory /> },
      { path: "/locations", exact: true, element: <Location /> },
      { path: "/gifts", exact: true, element: <Gift /> },
      { path: "/request", exact: true, element: <Request /> },
      { path: "/video", exact: true, element: <Video /> },
      { path: "/apply", exact: true, element: <Apply /> },
      { path: "/acceptedApplicants", exact: true, element: <AcceptedApplicants /> },
      { path: "/applicants/:jobId", exact: true, element: <Applicants /> },
      { path: "/shippingApi", exact: true, element: <Shipping1 /> },
    ],
  },
  {
    path: "/",

    children: [

      { path: "/login", exact: true, element: <Login /> },
   
    ],
  },
];

export default ThemeRoutes;
