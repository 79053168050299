import {
    FETCH_REQUEST_REQUEST,
    FETCH_REQUEST_SUCCESS,
    FETCH_REQUEST_FAIL,
    ADD_REQUEST_REQUEST,
    ADD_REQUEST_SUCCESS,
    ADD_REQUEST_FAIL,
    DELETE_REQUEST_REQUEST,
    DELETE_REQUEST_SUCCESS,
    DELETE_REQUEST_FAIL,
    CLEAR,
  } from "./requestType";
  
  export const initialState = {
    loading: false,
    requests: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const RequestReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          requests: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_REQUEST_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_REQUEST_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_REQUEST_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: "",
        };
      case ADD_REQUEST_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
 
  
      case DELETE_REQUEST_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_REQUEST_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: "",
        };
      case DELETE_REQUEST_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default RequestReducer;
  