import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import * as XLSX from "xlsx";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//utils
import isAuth from "../../Utils/isAuth";

import ReactDatatable from "@ashvin27/react-datatable";
import { getGift } from "../../Redux/Gifts/giftAction";

const Gift = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, gifts, error, addError, editError, deleteError } =
    useSelector((state) => state.GiftReducer);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getGift());
  }, [dispatch]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (gifts?.data) {
      setRecords(gifts?.data);
    }
  }, [gifts]);
  const handleExportToExcel = () => {
    exportToExcel(records);
  };

  function exportToExcel(records) {
    if (!records || records.length === 0) {
      return;
    }
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter((entry, index) => index !== 0);
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as the first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "MasterClassTestimonial.xlsx");
  }
  const columns = [
    {
      field: "name",
      headerName: "User",
      flex: 1,

      renderCell: (record) => (
        <div className="d-flex align-items-center">
          <div className="ms-0">
            <div className="mb-0">
              {record?.row?.user?.first_name} {record?.row?.user?.last_name}
            </div>
            <span className="text-muted">{record?.row?.user?.email}</span>
          </div>
        </div>
      ),
    },
    // {
    //   field: "receiver_lastname",
    //   headerName: "Receiver Last Name",
    // flex: 1,},{
    {
      field: "title",
      headerName: "Product",
      flex: 1,

      renderCell: (record) => (
        <div className="d-flex align-items-center">
          <div className="ms-0">
            <div className="mb-0">{record?.row?.product?.title}</div>
            <span className="text-muted">{record?.row?.product?.itemcode}</span>
          </div>
        </div>
      ),
    },
    // {
    //   field: "city",
    //   headerName: "City",
    //   flex: 1,
    // },
    // {
    //   field: "street_address",
    //   headerName: "Street Address",
    //   flex: 1,
    // },
    // {
    //   field: "building_name",
    //   headerName: "Building",
    //   flex: 1,
    // },
    // {
    //   field: "zipcode",
    //   headerName: "ZipCode",
    //   flex: 1,
    // },
    // {
    //   field: "region",
    //   headerName: "Region",
    // },
    {
      field: "special_instructions",
      headerName: "Special Instructions",
      flex: 2,
    },
    //  {
    //   field: "number",
    //   headerName: "Gift Number",
    // },
    // {
    //   field: "month",
    //   headerName: "Month",
    // },
    // {
    //   field: "year",
    //   headerName: "Year",
    // },
    // {
    //   field: "billing_id",
    //   headerName: "Billing ID",
    // },
  ];

  return (
    <div>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Gifts</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Card Details
          </CardSubtitle>

          <DataGrid
            className="table"
            rows={records}
            columns={columns}
            loading={loading}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection={false}
            density="comfortable"
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Gift;
