export const FETCH_VACANCY_REQUEST = "FETCH_VACANCY_REQUEST";
export const FETCH_VACANCY_SUCCESS = "FETCH_VACANCY_SUCCESS";
export const FETCH_VACANCY_FAIL = "FETCH_VACANCY_FAIL";

export const ADD_VACANCY_REQUEST = "ADD_VACANCY_REQUEST";
export const ADD_VACANCY_SUCCESS = "ADD_VACANCY_SUCCESS";
export const ADD_VACANCY_FAIL = "ADD_VACANCY_FAIL";

export const EDIT_VACANCY_REQUEST = "EDIT_VACANCY_REQUEST";
export const EDIT_VACANCY_SUCCESS = "EDIT_VACANCY_SUCESS";
export const EDIT_VACANCY_FAIL = "EDIT_VACANCY_FAIL";

export const DELETE_VACANCY_REQUEST = "DELETE_VACANCY_REQUEST";
export const DELETE_VACANCY_SUCCESS = "DELETE_VACANCY_SUCESS";
export const DELETE_VACANCY_FAIL = "DELETE_VACANCY_FAIL";

export const CLEAR = "CLEAR";
