import {
    FETCH_PRODUCT_REQUEST,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAIL,
  
      
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
  
    EDIT_PRODUCT_REQUEST,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAIL,
  
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
  
    FETCH_FEATURED_PRODUCT_FAIL,
    FETCH_FEATURED_PRODUCT_REQUEST,
    FETCH_FEATURED_PRODUCT_SUCCESS,

    CLEAR
  } from "./productType";
  
  export const initialState = {
    loading: false,
    products: [],
    featuredProducts:[],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
  
        case FETCH_PRODUCT_REQUEST:
          return {
            ...state,
            loading: true,
            error: "",
            addLoading: false,
            addError: "",
            editLoading: false,
            editError: "",
            deleteLoading: false,
            deleteError: "",
          };
        case FETCH_PRODUCT_SUCCESS:
          return {
            loading: false,
            products: action.payload,
            error: "",
            addLoading: false,
            addError: "",
            editLoading: false,
            editError: "",
            deleteLoading: false,
            deleteError: "",
          };
        case FETCH_PRODUCT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.message,
            addLoading: false,
            addError: "",
            editLoading: false,
            editError: "",
            deleteLoading: false,
            deleteError: "",
          };
    
        case FETCH_FEATURED_PRODUCT_REQUEST:{
          return {
            ...state,
            loading: true,
            error: "",
            addLoading: false,
            addError: "",
            editLoading: false,
            editError: "",
            deleteLoading: false,
            deleteError: "",
          };
        }

        case FETCH_FEATURED_PRODUCT_SUCCESS:{
          return {
            ...state,
            loading: false,
            featuredProducts: action.payload,
            error: "",
            addLoading: false,
            addError: "",
            editLoading: false,
            editError: "",
            deleteLoading: false,
            deleteError: "",
          };
        }

        case FETCH_FEATURED_PRODUCT_FAIL:{
          return {
            ...state,
            loading: false,
            error: action.payload.message,
            addLoading: false,
            addError: "",
            editLoading: false,
            editError: "",
            deleteLoading: false,
            deleteError: "",
          };
        }
      
        case ADD_PRODUCT_REQUEST:
          return {
            ...state,
            addLoading: true,
            addError: "",
          };
        case ADD_PRODUCT_SUCCESS:
          return {
            ...state,
            addLoading: false,
            addError: ""
          };
        case ADD_PRODUCT_FAIL:
          return {
            ...state,
            addLoading: false,
            addError: action.payload,
          };
    
        case EDIT_PRODUCT_REQUEST:
          return {
            ...state,
            editLoading: true,
            editError: "",
          };
        case EDIT_PRODUCT_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editError: ""
          };
        case EDIT_PRODUCT_FAIL:
          return {
            ...state,
            editLoading: false,
            editError: action.payload,
          };
    
        case DELETE_PRODUCT_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_PRODUCT_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_PRODUCT_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
    
    
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default ProductReducer;
  