export const FETCH_SHIPPING_REQUEST = "FETCH_SHIPPING_REQUEST";
export const FETCH_SHIPPING_SUCCESS = "FETCH_SHIPPING_SUCCESS";
export const FETCH_SHIPPING_FAIL = "FETCH_SHIPPING_FAIL";

export const ADD_SHIPPING_REQUEST = "ADD_SHIPPING_REQUEST";
export const ADD_SHIPPING_SUCCESS = "ADD_SHIPPING_SUCESS";
export const ADD_SHIPPING_FAIL = "ADD_SHIPPING_FAIL";

export const EDIT_SHIPPING_REQUEST = "EDIT_SHIPPING_REQUEST";
export const EDIT_SHIPPING_SUCCESS = "EDIT_SHIPPING_SUCESS";
export const EDIT_SHIPPING_FAIL = "EDIT_SHIPPING_FAIL";

export const DELETE_SHIPPING_REQUEST = "DELETE_SHIPPING_REQUEST";
export const DELETE_SHIPPING_SUCCESS = "DELETE_SHIPPING_SUCESS";
export const DELETE_SHIPPING_FAIL = "DELETE_SHIPPING_FAIL";


export const CLEAR = "CLEAR";