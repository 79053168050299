import {
    FETCH_CAREER_REQUEST,
    FETCH_CAREER_SUCCESS,
    FETCH_CAREER_FAIL,
  
   
  
    CLEAR
  } from "./CareerType";
  
  export const initialState = {
    loading: false,
    career: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const CareerReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_CAREER_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_CAREER_SUCCESS:
        return {
          loading: false,
          career: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_CAREER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
    
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  export default CareerReducer;
