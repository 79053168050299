export const FETCH_PREOWNEDCATEGORY_REQUEST = "FETCH_PREOWNEDCATEGORY_REQUEST";
export const FETCH_PREOWNEDCATEGORY_SUCCESS = "FETCH_PREOWNEDCATEGORY_SUCCESS";
export const FETCH_PREOWNEDCATEGORY_FAIL = "FETCH_PREOWNEDCATEGORY_FAIL";

export const ADD_PREOWNEDCATEGORY_REQUEST = "ADD_PREOWNEDCATEGORY_REQUEST";
export const ADD_PREOWNEDCATEGORY_SUCCESS = "ADD_PREOWNEDCATEGORY_SUCESS";
export const ADD_PREOWNEDCATEGORY_FAIL = "ADD_PREOWNEDCATEGORY_FAIL";

export const EDIT_PREOWNEDCATEGORY_REQUEST = "EDIT_PREOWNEDCATEGORY_REQUEST";
export const EDIT_PREOWNEDCATEGORY_SUCCESS = "EDIT_PREOWNEDCATEGORY_SUCESS";
export const EDIT_PREOWNEDCATEGORY_FAIL = "EDIT_PREOWNEDCATEGORY_FAIL";

export const DELETE_PREOWNEDCATEGORY_REQUEST = "DELETE_PREOWNEDCATEGORY_REQUEST";
export const DELETE_PREOWNEDCATEGORY_SUCCESS = "DELETE_PREOWNEDCATEGORY_SUCESS";
export const DELETE_PREOWNEDCATEGORY_FAIL = "DELETE_PREOWNEDCATEGORY_FAIL";


export const CLEAR = "CLEAR";