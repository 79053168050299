import {
  FETCH_FEATUREDBRAND_REQUEST,
  FETCH_FEATUREDBRAND_SUCCESS,
  FETCH_FEATUREDBRAND_FAIL,

    
  ADD_FEATUREDBRAND_REQUEST,
  ADD_FEATUREDBRAND_SUCCESS,
  ADD_FEATUREDBRAND_FAIL,

  EDIT_FEATUREDBRAND_REQUEST,
  EDIT_FEATUREDBRAND_SUCCESS,
  EDIT_FEATUREDBRAND_FAIL,

  DELETE_FEATUREDBRAND_REQUEST,
  DELETE_FEATUREDBRAND_SUCCESS,
  DELETE_FEATUREDBRAND_FAIL,

 

  CLEAR,
  CHANGE_FEATUREDBRAND_REQUEST,
  CHANGE_FEATUREDBRAND_SUCCESS,
  CHANGE_FEATUREDBRAND_FAIL,
  DELETE_ONE_FEATURED_BRAND_IMAGE_FAIL,
  DELETE_ONE_FEATURED_BRAND_IMAGE_REQUEST,
  DELETE_ONE_FEATURED_BRAND_IMAGE_SUCCESS
} from "./FeaturedBrandTypes";

export const initialState = {
  loading: false,
  featuredBrands: [],
  error: "",

  addLoading: false,
  addError: "",

  editLoading: false,
  editError: "",

  deleteLoading: false,
  deleteError: "",

  changeLoading: false,
  changeError: "",
};

const FeaturedBrandReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_FEATUREDBRAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
        changeLoading: false,
        changeError: "",
      };
    case FETCH_FEATUREDBRAND_SUCCESS:
      return {
        loading: false,
        featuredBrands: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
        changeLoading: false,
        changeError: "",
      };
    case FETCH_FEATUREDBRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
        changeLoading:false,
        changeError:""
      };

      case ADD_FEATUREDBRAND_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_FEATUREDBRAND_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: ""
        };
      case ADD_FEATUREDBRAND_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_FEATUREDBRAND_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_FEATUREDBRAND_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_FEATUREDBRAND_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_FEATUREDBRAND_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_FEATUREDBRAND_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: ""
        };
      case DELETE_FEATUREDBRAND_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
      case CHANGE_FEATUREDBRAND_REQUEST:
        console.log("CHANGE_FEATUREDBRAND_REQUEST")
        return{
          ...state,
          changeLoading: true,
          changeError:"",
          
        }
      case CHANGE_FEATUREDBRAND_SUCCESS:
        
        return{
          ...state,
          changeLoading: false,
          changeError:"",
        }
      case CHANGE_FEATUREDBRAND_FAIL:
        return{
          ...state,
          changeLoading: false,
          changeError:action.payload,
        }
        case DELETE_ONE_FEATURED_BRAND_IMAGE_FAIL:
          return {
            ...state,
            loadingOneProduct:false,
            errorOneProduct:action.payload,
          }
        case DELETE_ONE_FEATURED_BRAND_IMAGE_REQUEST:
          return {
            ...state,
            loadingOneProduct:true,
            errorOneProduct:false,
          }
        case DELETE_ONE_FEATURED_BRAND_IMAGE_SUCCESS:
          return {
            ...state,
            loadingOneProduct:false,
            errorOneProduct:false,
          }
  
  

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};
export default FeaturedBrandReducer;
